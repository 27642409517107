/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { YMaps } from "react-yandex-maps"
import ThemeProvider from "./src/components/layout/themeprovider"


export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      <YMaps
        query={{
          lang: "RU",
          apikey: "da02298e-23f1-4832-9f92-56e073b3f2e3",
        }}
      >
        {element}
      </YMaps>
    </ThemeProvider>
  )
}
