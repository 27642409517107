export default {
  globalWidth: "1280px",
  colors: {
    footer: "#516478",
    sideBarLink: "#3a4649",
    body: "#f5f5f5",
    globalFont: "#212529",
    heading: "black",
    navLink: "#3a4649",
    secondary: "#3a4649",
    activeLink: "#c4281d",
    hoverLink: "black",
    footerFont: "white",
    footerHeading: "white",
    line: "rgb(229, 229, 229)",
  },
}
