import { ThemeProvider } from "emotion-theming"
import media from "./media"
import theme from "./theme"


import React from 'react'

const Theme = ({children}) => {
    return <ThemeProvider theme={{ ...theme, ...media }}>{children}</ThemeProvider>
}

export default Theme